@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');

* {
    font-family: 'Noto Sans Mono', monospace;
    background-color: #1d2021;
    color: #ebdbb2;
    font-size: 13px;
    line-height: 1.5;
}

.website p {
    width: 500px;
    word-wrap: break-word;
    overscroll-behavior: contain;
}

.input-box {
    background-color: transparent;
    outline: none;
    border: none;
    height: 1em;
}

.output-area {
    white-space: pre;
    text-overflow: clip;
}

.output-box {
    width: 100%;
    height: 100%;
}

.output-box p {
    display: flex;
    flex-wrap: wrap;
    overflow-wrap: anywhere;
}

.guest {
    color: rgb(215, 153, 33);
}

.prompt-stuff {
    color: rgb(168, 153, 132);
}

.output-prompt-stuff {
    color: rgb(168, 153, 132);
    padding-right: 2px;
}

.email {
    color: rgb(152, 151, 26);
}

.start_banner {
    font-family:monospace;
    white-space: pre;
    font-size: large;
}